import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import { RouterNavLink } from 'components';
import * as React from 'react';
import brandLogo from '../../assets/tlx_logo_small.png';
import { AppRouteProps } from '../../routes';
import { SidebarLogo } from './components';
import { useStyles } from './styles';
import { useGlobalState } from 'App.state';

interface Props {
  openDrawer: () => void;
  closeDrawer: () => void;
  open: boolean;

  routes?: AppRouteProps[];
  bgImage?: string;
}

export const Sidebar: React.FC<Props> = ({
  bgImage,
  routes,
  open,
  openDrawer,
  closeDrawer,
}) => {
  const {
    breakpoints: { up },
  } = useTheme();
  const isLg = useMediaQuery(up('lg'));

  React.useEffect(() => {
    // When screen is large, we set the open state to false, so when the user minimizes screen size,
    // the drawer will start in a closed state.
    if (isLg && open) {
      closeDrawer();
    }
  });

  const classes = useStyles();

  const brandUrl = 'https://www.talexio.com';

  const backgroundStyle: React.CSSProperties | undefined = bgImage
    ? { backgroundImage: `url(${bgImage})` }
    : undefined;

  // Taken from: https://material-ui.com/demos/drawers/ - Swipeable Temporary drawer
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [clockingsAuthUser] = useGlobalState('clockingsAuthUser');

  return (
    <SwipeableDrawer
      anchor="left"
      ModalProps={{ keepMounted: true }}
      variant={isLg ? 'permanent' : 'temporary'}
      open={isLg || open}
      onClose={closeDrawer}
      onOpen={openDrawer}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      classes={{ paper: classes.drawer }}
    >
      <List dense className={classes.list}>
        {routes &&
          routes.map(
            ({
              path,
              icon: Icon,
              sidebarName,
              headerTitle,
              hideFromMenu,
              adminOnly,
            }) =>
              !hideFromMenu &&
              (!adminOnly || !clockingsAuthUser?.vmsOnly) && (
                <ListItem
                  button
                  onClick={closeDrawer}
                  className={classes.item}
                  key={path}
                  component={RouterNavLink}
                  to={path}
                  activeClassName={classes.itemActive}
                >
                  {Icon && (
                    <ListItemIcon className={classes.itemIcon}>
                      <Icon />
                    </ListItemIcon>
                  )}

                  <ListItemText
                    secondary={
                      typeof sidebarName === 'string' ||
                      typeof sidebarName === 'object'
                        ? sidebarName
                        : headerTitle
                    }
                    secondaryTypographyProps={{
                      className: classes.itemText,
                    }}
                  />
                </ListItem>
              ),
          )}
      </List>
      <SidebarLogo
        linkHref={brandUrl}
        logoSrc={brandLogo}
        text={
          <React.Fragment>
            device <strong>manager</strong>
          </React.Fragment>
        }
      />

      <div className={classes.background} style={backgroundStyle} />
    </SwipeableDrawer>
  );
};
