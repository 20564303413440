import { SvgIcon } from '@material-ui/core';
import {
  CloudUpload,
  Dashboard,
  People,
  Schedule,
  Security,
  Work,
} from '@material-ui/icons';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import {
  Dashboard as DashboardView,
  Employees,
  Devices,
  Transactions,
  AdminUsers,
  FtpConfigs,
} from '../../views';

interface SubPage {
  path: string;
  headerTitle: string;
  icon: typeof SvgIcon;
  searchTitle: string;
}

// Children not supported by AppLayout
export interface AppRouteProps extends Omit<RouteProps, 'children'> {
  path: string;
  headerTitle: string;
  sidebarName?: React.ReactNode;
  icon?: typeof SvgIcon;
  hideFromMenu?: boolean;
  subPages?: SubPage[];
  adminOnly?: boolean;
}

export function getRoutes() {
  const routes: AppRouteProps[] = [
    {
      path: '/dashboard',
      headerTitle: 'Dashboard',
      icon: Dashboard,
      component: DashboardView,
    },
    {
      path: '/employees',
      headerTitle: 'Employees',
      icon: People,
      component: Employees,
    },
    {
      path: '/devices',
      headerTitle: 'Devices',
      icon: Work,
      component: Devices,
    },
    {
      path: '/transactions',
      headerTitle: 'Transactions',
      icon: Schedule,
      component: Transactions,
    },
    {
      path: '/ftp-settings',
      headerTitle: 'Cloud sync',
      icon: CloudUpload,
      component: FtpConfigs,
      adminOnly: true,
    },
    {
      path: '/admin-users',
      headerTitle: 'Admins',
      icon: Security,
      component: AdminUsers,
      adminOnly: true,
    },
  ];
  return routes;
}
